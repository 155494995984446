import React, { useState } from "react";
import staff1 from "../img/staff1.png";

const AboutPractice = ({ forceExpand = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="w-full flex justify-center items-center py-12 bg-white border-b">
      <div className="w-2/3 mt-4 h-full flex flex-row flex-wrap-reverse justify-center items-end">
        <img alt="staff1" className="w-72 h-full object-contain" src={staff1} />
        <div
          className={`w-72 mb-4 relative ${
            isExpanded || forceExpand ? "h-auto" : "h-70 overflow-hidden"
          }`}
        >
          <div
            className={`w-full flex flex-col justify-start ${
              isExpanded || forceExpand ? "h-auto" : "h-68 overflow-hidden"
            }`}
          >
            <div className="px-4">
              <div className="w-full border-b">
                <p className="text-2xl font-serif text-sunstate-primary text-center pb-4 tracking-wider">
                  About Our Practice
                </p>
              </div>
              <div className="overflow-hidden text-lg text-left text-gray-600">
                <p className="pt-5">
                  We are encouraging all of our patients to keep their follow up
                  appointments or new necessary consultation visits through the
                  Tele-Health to minimize exposure.
                </p>
                <p className="mt-6">
                  At Sunstate Medical Associates, the physicians establish
                  long-lasting relationships with patients while helping them
                  achieve optimal health. The team takes a whole-body approach
                  to medicine, ensuring patients experience superior care
                  promoting their overall health and wellness.
                </p>
                <p className="mt-6">
                  Sunstate Medical Associates provides a wide range of services,
                  including care for chronic conditions such as arthritis,
                  asthma, diabetes, and hypertension. The practice also teams
                  with patients for weight loss, dermatology needs, thyroid
                  issues, depression and anxiety, and sleep disorders.
                </p>
                <p className="mt-6">
                  For preventative and primary care, the practice provides
                  geriatric medicine, immunizations, wellness exams, well-woman
                  exams, and contraception consultations. Sunstate Medical
                  Associates also treats patients with high cholesterol, back
                  pain, and ADHD
                </p>
                <p className="mt-6">
                  If patients need lab testing, the practice performs them
                  onsite, including electrocardiograms (EKG), diagnostic
                  ultrasounds, skin surgeries, and blood tests.
                </p>
                <p className="mt-6">
                  The Sunstate Medical Associates team stays knowledgeable and
                  trained in the industry’s latest, advanced practices, offering
                  patients excellent care for any medical concern. They thrive
                  on educating patients and helping them stay well, treating
                  them if they get sick, and referrals to specialists when
                  needed.
                </p>
                <p className="mt-6">
                  To schedule an appointment at Sunstate Medical Associates,
                  call the office today or book an appointment online.
                </p>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 pl-4">
            <button
              className={`border-b-2 border-sunstate-primary text-lg text-sunstate-primary hover:text-gray-600 uppercase font-semibold ${
                isExpanded || forceExpand ? "hidden" : "inline-block"
              }`}
              onClick={() => setIsExpanded(true)}
            >
              Read More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPractice;
