import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import AboutPractice from "../components/AboutPractice";
import TestimonialSlider from "../components/TestimonialSlider";
import Information from "../components/Information";

const About = () => {
  return (
    <Layout>
      <PageHeader text="About Sunstate Medical Associates" />
      <AboutPractice forceExpand={true} />
      <TestimonialSlider />
      <Information />
    </Layout>
  );
};

export default About;
